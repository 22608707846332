import { ethers } from "ethers";

const PRECISION = 3;

export const weiToEth = (value, decimals = 18) => {
  const displayValue =
    ethers.BigNumber.from(value)
      .div(ethers.BigNumber.from(10).pow(decimals - PRECISION))
      .toNumber() / ethers.BigNumber.from(10).pow(PRECISION).toNumber();
  return formatNumber(displayValue);
};

export const ethToWei = (value, decimals = 18) => {
  const numberOfTokens = ethers.BigNumber.from(
    parseFloat(value.replace(",", "")) *
      ethers.BigNumber.from(10).pow(PRECISION).toNumber()
  )
    .mul(ethers.BigNumber.from(10).pow(decimals - PRECISION))
    .toString();

  return numberOfTokens;
};

export const formatNumber = (num) => {
  return new Intl.NumberFormat("en-US").format(num);
};

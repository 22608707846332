import { ethers } from "ethers";
import useChain from "./useChain";

function useToken() {
  const { provider, signer } = useChain();
  const abi = [
    "function name() view returns (string)",
    "function symbol() view returns (string)",
    "function decimals() view returns (uint8)",
    "function totalSupply() view returns (uint)",
    "function balanceOf(address) view returns (uint)",
    "function allowance(address,address) view returns (uint256)",
    "function approve(address,uint) returns (bool)",
  ];
  return (tokenAddress) => {
    const contract = new ethers.Contract(tokenAddress, abi, provider);
    return contract.connect(signer);
  };
}

export default useToken;

import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

const NotAvailable = () => {
  return (
    <Card className="text-center" bg="danger" text="white">
      <Card.Body>
        <Card.Title>MetaMask not available</Card.Title>
        <Card.Text>
          You need to install the MetaMask extension before using the app.
        </Card.Text>
        <Button
          variant="light"
          bordered
          target="_blank"
          href="https://metamask.io/"
        >
          Download MetaMask
        </Button>
      </Card.Body>
    </Card>
  );
};

export default NotAvailable;

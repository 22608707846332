import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { weiToEth } from "../../utils";
import useMultisend from "../../services/useMultisend";
import { SendStoreContext } from "../../store/Send";

const SendTokens = () => {
  const { setError, token, recipients } = React.useContext(SendStoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const multisend = useMultisend();

  const sendTokens = async () => {
    setError(null);
    setIsLoading(true);

    const addresses = recipients.map((recipient) => recipient[0]);
    const amounts = recipients.map((recipient) => recipient[1]);

    try {
      const mutisendTx = await multisend.multisendToken(
        token.address,
        addresses,
        amounts,
        {
          gasLimit: 6000000,
        }
      );
      await mutisendTx.wait(1);
    } catch (e) {
      setError(e?.message ? e?.message : e);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Alert variant="warning">Send tokens to the following addresses?</Alert>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Recipient</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {recipients.map((recipient) => (
            <tr key={recipient[0]}>
              <td>{recipient[0]}</td>
              <td>
                {token?.decimals
                  ? weiToEth(recipient[1], token.decimals)
                  : weiToEth(recipients[1])}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button
        variant="success"
        block={true}
        disabled={isLoading}
        size="lg"
        type="submit"
        onClick={(ev) => {
          ev.preventDefault();
          sendTokens();
        }}
      >
        {isLoading ? "Sending..." : "Send"}
      </Button>
    </>
  );
};

export default SendTokens;

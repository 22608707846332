import React, { useState } from "react";

export const SendStoreContext = React.createContext(null);

const SendStoreProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState("recipients");
  const [error, setError] = useState(null);
  const [token, setToken] = useState();
  const [recipients, setRecipients] = useState([]);

  const store = {
    isLoading,
    setIsLoading,
    step,
    setStep,
    error,
    setError,
    token,
    setToken,
    recipients,
    setRecipients,
  };

  return (
    <SendStoreContext.Provider value={store}>
      {children}
    </SendStoreContext.Provider>
  );
};

export default SendStoreProvider;

import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useMetaMask } from "metamask-react";
import Form from "react-bootstrap/Form";
import useToken from "../../services/useToken";
import { formatNumber } from "../../utils";
import { SendStoreContext } from "../../store/Send";

const TokenInfo = () => {
  const { account } = useMetaMask();
  const { step, token, setToken } = React.useContext(SendStoreContext);

  const [tokenAddress, setTokenAddress] = useState(
    "0xf001937650bb4f62b57521824b2c20f5b91bea05"
  );
  const uToken = useToken();

  useEffect(() => {
    const getToken = async () => {
      if (
        !ethers.utils.isAddress(tokenAddress) ||
        !ethers.utils.isAddress(account)
      ) {
        setToken(null);
        return;
      }

      const t = uToken(tokenAddress);
      const name = await t.name();
      const symbol = await t.symbol();
      const decimals = (await t.decimals()).toString();
      const decimalsDiv = ethers.BigNumber.from(10).pow(
        ethers.BigNumber.from(decimals)
      );
      const totalSupply = formatNumber(
        (await t.totalSupply()).div(decimalsDiv).toString()
      );
      const balance = formatNumber(
        (await t.balanceOf(account)).div(decimalsDiv).toString()
      );
      const tokenInfo = {
        address: tokenAddress,
        name,
        symbol,
        decimals,
        totalSupply,
        balance,
      };
      setToken(tokenInfo);
    };
    getToken();
  }, [tokenAddress, account]);

  return (
    <Form>
      <Form.Group controlId="token">
        <Form.Label>Token Address</Form.Label>
        <Form.Control
          size="lg"
          type="text"
          isInvalid={!ethers.utils.isAddress(tokenAddress)}
          isValid={ethers.utils.isAddress(tokenAddress)}
          disabled={step !== "recipients"}
          onChange={(ev) => {
            setTokenAddress(ev.target.value);
          }}
          value={tokenAddress}
        />
        <Form.Text className="text-muted">
          {token && (
            <>
              Token details:
              <ul>
                <li>Name: {token.name}</li>
                <li>Symbol: {token.symbol}</li>
                <li>Decimals: {token.decimals}</li>
                <li>Total Supply: {token.totalSupply}</li>
                <li>Balance: {token.balance}</li>
              </ul>
            </>
          )}
        </Form.Text>
      </Form.Group>
    </Form>
  );
};

export default TokenInfo;

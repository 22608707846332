import { ethers } from "ethers";
import { useMetaMask } from "metamask-react";
import { useMemo } from "react";

function useChain() {
  const { chainId, ethereum } = useMetaMask();
  const provider = useMemo(() => new ethers.providers.Web3Provider(ethereum), [
    chainId,
  ]);
  const signer = provider.getSigner();
  return { chainId, provider, signer };
}

export default useChain;

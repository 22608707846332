import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useMetaMask } from "metamask-react";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import constants from "../../constants";
import useToken from "../../services/useToken";
import { weiToEth } from "../../utils";
import { SendStoreContext } from "../../store/Send";

const Approve = () => {
  const { setStep, setError, token, recipients } =
    React.useContext(SendStoreContext);
  const { account } = useMetaMask();
  const [preapprovedAllowance, setPreapprovedAllowance] = useState(
    ethers.BigNumber.from(0)
  );
  const [isLoading, setIsLoading] = useState(false);

  const uToken = useToken()(token.address);

  useEffect(() => {
    const getAllowance = async () => {
      const allowance = await uToken.allowance(
        account,
        constants.multisendAddress
      );
      setPreapprovedAllowance(allowance);
    };
    getAllowance();
  }, [account, token.address]);

  const total = recipients.reduce(
    (acc, curr) => acc.add(ethers.BigNumber.from(curr[1])),
    ethers.BigNumber.from("0")
  );

  const approve = async () => {
    setError(null);
    setIsLoading(true);

    try {
      const approveTx = await uToken.approve(
        constants.multisendAddress,
        total,
        {
          gasLimit: 6000000,
        }
      );
      await approveTx.wait(1);
      setStep("send");
    } catch (e) {
      setError(e?.message ? e?.message : e);
    } finally {
      setIsLoading(false);
    }
  };

  const isEnoughAllowance = preapprovedAllowance.gte(total);

  return (
    <>
      {isEnoughAllowance && (
        <Alert variant="success">
          Existing allowence:
          <strong>
            {token?.decimals
              ? weiToEth(preapprovedAllowance.toString(), token.decimals)
              : weiToEth(total)}{" "}
            {token.symbol}
          </strong>{" "}
        </Alert>
      )}
      {!isEnoughAllowance && (
        <Alert variant="warning">
          Approve{" "}
          <strong>
            {token?.decimals
              ? weiToEth(total, token.decimals)
              : weiToEth(total)}{" "}
            {token.symbol}
          </strong>{" "}
          to <strong>{constants.multisendAddress}</strong>?
        </Alert>
      )}
      <Button
        variant="success"
        block={true}
        disabled={isLoading}
        size="lg"
        type="submit"
        onClick={(ev) => {
          ev.preventDefault();
          if (isEnoughAllowance) {
            setStep("send");
          } else {
            approve();
          }
        }}
      >
        {isEnoughAllowance && (isLoading ? "Next..." : "Next")}
        {!isEnoughAllowance && (isLoading ? "Approving..." : "Approve")}
      </Button>
    </>
  );
};

export default Approve;

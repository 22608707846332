import React from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { weiToEth } from "../../utils";
import { SendStoreContext } from "../../store/Send";

const Confirmation = () => {
  const { setStep, token, recipients } = React.useContext(SendStoreContext);

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Recipient</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {recipients.map((recipient) => (
            <tr key={recipient[0]}>
              <td>{recipient[0]}</td>
              <td>
                {token?.decimals
                  ? weiToEth(recipient[1], token.decimals)
                  : weiToEth(recipients[1])}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button
        variant="success"
        block={true}
        size="lg"
        type="submit"
        onClick={(ev) => {
          ev.preventDefault();
          setStep("approve");
        }}
      >
        Confirm
      </Button>
    </>
  );
};

export default Confirmation;
